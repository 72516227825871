import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';

import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';

import imgAvif from '../../../../../../client/assets_seller/homepage/banner-claim.avif';
import imgJpg from '../../../../../../client/assets_seller/homepage/banner-claim.jpg';
import imgWebp from '../../../../../../client/assets_seller/homepage/banner-claim.webp';
import { useBreakpoint } from '../../../../../components/misc/Breakpoints/Breakpoints';
import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import LocalTexts from '../../HomepageTexts.json';

import styles from './ClaimBannerWithPicture.scss';

const LocalT = new MDText(LocalTexts);

const t = (key: string) => LocalT.translate(key) as string;

const callToActionProps = {
  ctaButtonLabel: t('claim.cta.buttonLabel'),
  ctaTitle: t('claimMoreMoney.title'),
  onClick: () => HOMEPAGE_GA_TAGS.SECTION_CTA_CLICKED(HOMEPAGE_SECTIONS.CLAIM),
  redirectTo: '/',
  showOnMobile: true,
};

type Props = {
  readonly className?: string;
}

export const ClaimBannerWithPicture = ({ className }: Props) => {
  const onRefTrigger = () =>
    HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.CLAIM);
  const { setRef } = usePageRefEvents({ onRefTrigger });

  const breakpoints = useBreakpoint();
  const isTabletMobile = !breakpoints?.maxWidth?.breakpointTabletMobile;
  return (
    <div
      ref={(ref) => setRef(ref)}
      className={cx(className)}
      data-testid='claimBannerWithPicture'
    >
      <Content className={styles.component}>
        <picture>
          <source srcSet={imgAvif} type="image/avif" />
          <source srcSet={imgWebp} type="image/webp" />
          <img
            alt={t('claimMoreMoney.imgAlt')}
            className={styles.thumbnail}
            loading='lazy'
            src={imgJpg}
          />
        </picture>
        <div className={styles.chevron}></div>
        <div className={styles.contentBlock}>
          <h3 className={styles.title} data-cy="claimSection">{callToActionProps.ctaTitle}</h3>
          <div className={styles.cta}>
            <Button
              as={Link}
              fullWidth={isTabletMobile}
              icon='chevron'
              label={callToActionProps.ctaButtonLabel}
              onClick={callToActionProps.onClick}
              size={isTabletMobile ? 'large' : 'medium'}
              to={callToActionProps.redirectTo}
            />
          </div>
        </div>
      </Content>
    </div>

  );
};
